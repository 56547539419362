/**=====================
    63. Box-layout CSS start
==========================**/

.box-layout {
  background-image: url(../../images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba($white, 0.5);

  @media only screen and (min-width: 1280px) {
    .left-header {
      .mega-menu-container {
        width: calc(100vw - 870px);
        left: -32px;
        min-width: 1000px;
      }
    }
  
    .page-wrapper,
    &.page-wrapper {
      &.box-layout {
        padding-top: 40px;
        margin-top: 0 !important;
      }
      &.compact-wrapper {
        margin-top: $box-layout-space;
  
        .page-body-wrapper {
          div.sidebar-wrapper {
            width: 230px;
            &.close_icon {
              width: auto;
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 196px);
                  > li {
                    .sidebar-link {
                      display: inline-block;
                    }
                    a {
                      svg {
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
               ~ footer {
                 margin-left: 0;
               }
              &:hover {
                .sidebar-main {
                  .sidebar-links {
                    > li {
                      .sidebar-link {
                        display: block;
                      }
                      a {
                        svg {
                          margin-right: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
             
          }
  
          .sidebar-wrapper {
            top: $box-layout-space;
            border-radius: 10px 0 0 10px;
  
            .logo-wrapper {
              box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
            }
  
            .sidebar-main {
              .sidebar-links {
                margin-top: 10px;
                margin-bottom: 20px;
                height: calc(100vh - 191px);
              }
            }
          }
  
          .page-body {
            min-height: calc(100vh - 130px);
            margin-top: 0;
            top: 30px;
            overflow: scroll;
            max-height: calc(100vh - 220px);
            padding-bottom: 80px;
            margin-left: 230px;
  
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
  
            &::-webkit-scrollbar {
              width: 3px;
            }
  
            &::-webkit-scrollbar-thumb {
              background-color: rgba(230, 230, 230, 0.15);
            }
          }
        }
  
        
        .page-header {
          .header-wrapper {
            .left-header {
              display: inline-block;
            }
  
            .toggle-sidebar {
              display: inline-block;
              left: 30px;
            }
          }
        }
      }

      &.compact-sidebar {
        .page-header {
          padding-left: 148px;

          .header-wrapper {
            .left-header {
              flex: 0 0 30%;
              max-width: 30%;
            }
          }
        }

        .page-body-wrapper {
          margin-top: 40px;

          div.sidebar-wrapper {
            top: 40px;

            &.close_icon {
              display: none;
              transition: 0.5s;
            }

            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 166px);
                margin: 0;

                li {

                  .sidebar-submenu,
                  .mega-menu-container {
                    left: 23%;
                    top: 40px;
                    height: calc(100vh - 84px);
                  }

                  a {
                    &.active {

                      ~.sidebar-submenu,
                      ~.mega-menu-container {
                        animation: unset;
                      }
                    }
                  }
                }
              }
            }

          }

          .footer {
            margin-left: 0;
          }

          .page-body {
            min-height: calc(100vh - 90px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 170px);
            padding-bottom: 80px;

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }

            &::-webkit-scrollbar {
              width: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(230, 230, 230, 0.15);
            }
          }
        }

        &.compact-small {
          .page-header {
            padding-left: 90px;
            padding-top: 40px;
            margin-top: 0;

            &.close_icon {
              padding-left: 0;
            }
          }

          .page-body-wrapper {
            div.sidebar-wrapper {
              .sidebar-main {
                .sidebar-links {
                  li {

                    .sidebar-submenu,
                    .mega-menu-container {
                      left: 410px;
                    }
                  }
                }
              }
            }
          }
        }
      }

  
      &.horizontal-wrapper {
        &:before {
          display: none;
        }
  
        .page-body-wrapper {
          .page-body {
            margin-top: 70px;
            min-height: calc(100vh - 310px);
            max-height: calc(100vh - 230px);
            overflow: scroll;
  
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
  
            &::-webkit-scrollbar {
              width: 6px;
            }
  
            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
  
          .footer {
            padding-left: 15px;
          }
  
          .sidebar-wrapper {
            width: 1280px;
            top: 72px;
            margin: 0;
            overflow-x: unset;
            position: relative;
  
            .sidebar-main {
              #sidebar-menu {
                width: 1280px;
                left: 0%;
                transform: translate(0, 0);
                top: 125px;
                background-color: $white;
              }
            }
          }
        }
  
        .page-header {
          padding-left: 0;
        }
      }

      &.modern-type {
        .page-header {
          padding-left: 0px;

          .header-wrapper {
            .left-header {
              flex: 0 0 26%;
              max-width: 26%;
            }

            .nav-right {
              flex: 0 0 60%;
              max-width: 60%;
            }
          }
        }

        .page-body-wrapper {
          .page-body {
            margin-left: 260px;
            top: 80px;
          }

          .sidebar-wrapper {
            top: 120px;

            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 311px);
              }
            }

            &.close_icon {
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 316px);
                }
              }

              ~footer {
                width: 1280px;
                left: 50%;
                margin-left: 0;
              }
            }
          }

          .footer {
            margin-left: 0;
          }
        }
      }
  
      .page-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left: 230px;
        margin-top: $box-layout-space;
        border-radius: 10px;
        width: unset;
  
        &.close_icon {
          padding-left: 0;
          margin-left: auto;
          transition: $sidebar-transition;
        }
  
        .header-wrapper {
          border: 1px solid #f8f8f8;
          border-radius: 10px 10px 0 0;
  
        }
      }

      &.horizontal-wrapper {
        .page-header {
          .header-wrapper {

            .nav-right {
              flex: 0 0 63%;
              max-width: 63%;
            }
          }
        }
      }

      &.material-type {
        .page-body-wrapper {
          margin-top: 0;

          .page-body {
            margin-top: 0;
          }

          .sidebar-wrapper {
            top: 0;
          }
        }
      }

      &.material-icon {
        .page-header {
          &.close_icon {
            margin-top: 40px !important;
          }
        }

        .page-body-wrapper {
          margin-top: 0;

          .page-body {
            top: 0;
            min-height: calc(100vh - 170px);
            padding-bottom: 40px;
          }
        }
      }

      &.advance-layout {
        .page-header {
          box-shadow: 0px 0px 10px rgba($black, 0.07);
        }

        .page-body-wrapper {
          margin-top: 0;
        }
      }
  
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        overflow: hidden;
        margin-top: 50px;
  
        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
  
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
  
        .browse {
          div[class^="col-"] {
            &:nth-child(3) {
              margin-top: 30px;
            }
          }
        }
  
        .active-order-table {
          max-width: 443px;
          overflow: auto;
  
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
  
        .activity {
          .media {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
  
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
  
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
  
        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 230px;
          margin-bottom: 40px !important;
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 0 0 10px;
        }
  
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
  
        .footer-fix {
          padding-left: 230px;
          left: unset;
        }
  
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
  
              >div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 30px;
                left: 15px;
              }
  
              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }
  
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
  
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
  
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
  
              img {
                width: 38%;
              }
            }
  
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
  
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
  
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
  
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
  
          img {
            opacity: 0.7;
          }
        }
  
        .browser-widget {
          img {
            height: 65px;
          }
        }
  
        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }
  
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
  
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
  
        .select2 {
          width: 901.781px;
        }
  
      }
      
      &.only-body {
        .page-body-wrapper {
          margin-top: 0;
        }
      }
  
      ul {
        &.close_icon {
          >li {
            label {
              padding: 0;
  
              &:after {
                display: none;
              }
            }
          }
        }
      }
  
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }
  
      #batchDelete {
        .jsgrid-grid-header {
          .jsgrid-table {
            tr {
              th {
                .btn {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }
  
      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
  
      .media.bitcoin-graph {
        display: block;
  
        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }
  
        .media-body {
          margin-top: 30px;
  
          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14PX;
              }
            }
  
            &.text-right {
              text-align: center !important;
            }
          }
        }
      }
  
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
  
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
  
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
  
      .page-builder {
        .ge-canvas.ge-layout-desktop {
          margin-top: $box-layout-space;
        }
  
        .ge-addRowGroup {
          margin-bottom: 10px;
        }
      }
  
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
  
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
  
      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }
  
      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }
  
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
  
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
  
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
  
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
  
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
  
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
  
      .box-col-4 {
        flex: 0 0 33.33%;
        max-width: 33%;
      }
  
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
  
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
  
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
  
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
  
        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
      }
  
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
  
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
  
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
  
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
  
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
  
      .touchspin {
        padding: 0 10px;
      }
  
      .vertical-menu-main {
        width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
  
        .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -320px !important;
        }
      }
  
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .page-wrapper {
      &.horizontal-wrapper {
        &.material-type {
          .page-body-wrapper {
            max-width: 1320px;
            .sidebar-wrapper {
              .sidebar-main {
                #sidebar-menu {
                  max-width: 1320px;
                }
              }
            }
          }
          footer {
            max-width: 1320px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    .page-wrapper {
      &.horizontal-wrapper {
        &.material-type {
          .page-body-wrapper {
            max-width: 1140px;
            .sidebar-wrapper {
              .sidebar-main {
                #sidebar-menu {
                  max-width: 1140px;
                }
              }
            }
          }
          footer {
            max-width: 1140px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1660px) and (min-width: 1440px) {
    .calendar-sec {
      order: unset;
    }

    .chart_data_right.second {
      display: none !important;
    }
  }

}

/**=====================
    63. Box-layout CSS ends
==========================**/